<ng-container *transloco="let t; read: 'import-mal-collection-modal'">

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{t('title')}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="ngbModal.close()"></button>
  </div>
  <div class="modal-body scrollable-modal">
    <p>{{t('description')}}</p>

    <ul>
      @for(stack of stacks; track stack.url) {
        <li class="mb-2">
          <div>
            <a [href]="stack.url" rel="noreferrer noopener" target="_blank">{{stack.title}}</a>
            <button class="btn btn-primary float-end" [disabled]="collectionMap && collectionMap.hasOwnProperty(stack.url)" (click)="importStack(stack)">Track</button>
          </div>
          <div>by {{stack.author}} • {{t('series-count', {num: stack.seriesCount | number})}} • <span><i class="fa-solid fa-layer-group me-1" aria-hidden="true"></i>{{t('restack-count', {num: stack.restackCount | number})}}</span></div>
        </li>
      } @empty {
        @if (isLoading) {
          <app-loading [loading]="isLoading"></app-loading>
        } @else {
          <p>{{t('nothing-found')}}</p>
        }
      }
    </ul>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="ngbModal.dismiss()">{{t('close')}}</button>
  </div>

</ng-container>
